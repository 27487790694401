// Load Styles
import '../scss/main.scss';

let hamburger_open = false;
const hamburger = document.querySelector('.hamburger');
const side_menu = document.querySelector('#side_menu');
const navbar = document.querySelector('#navbar');
const navbar_image = document.querySelector('#navbar_image');
const navbar_list = document.querySelectorAll('.navbar_list');
const navbar_trigger = document.querySelector('#navbar_trigger');

hamburger.addEventListener('click', function(){
    
    if(hamburger_open){ // to close
        hamburger.classList.remove('is-active');
        side_menu.style.right = '-280px';
        hamburger_open = false;
    } else {
        hamburger.classList.add('is-active');
        side_menu.style.right = '0px';
        hamburger_open = true;
    }

});
window.addEventListener('scroll', function() {
    var _y = window.pageYOffset;
    if(navbar){
        if(_y > 100) { 
            navbar.classList.remove('bg-transparent');
            navbar.classList.add('bg-light');
            navbar_list.forEach(function(elem){
                elem.classList.add("text-dark");
            });
            navbar_image.setAttribute('src', '/wp-content/themes/www.i-hankoya.co.jp/dist/images/common/logo-dark.png');
            // navbar_trigger.classList('');
        } else {
            navbar.classList.add('bg-transparent');
            navbar.classList.remove('bg-light');
            navbar_list.forEach(function(elem){
                elem.classList.remove("text-dark");
            });
            navbar_image.setAttribute('src', '/wp-content/themes/www.i-hankoya.co.jp/dist/images/common/logo-white.png');
        }
    }

}, {passive: true});